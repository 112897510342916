import { BusinessRoleClasses } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";

export const getToDoNavLinks = () => [
  {
    name: "Inbox",
    path: routes.userInbox.basePath,
    businessRoleClasses: BusinessRoleClasses.Provider,
    showBorder: true
  },
  {
    name: "Clinical tasks",
    path: routes.tasks.clinical
  },
  {
    name: "Follow-up tasks",
    path: routes.tasks.inbox
  },
  {
    name: "Clinical reminders",
    path: routes.tasks.reminders
  },
  {
    name: "Follow up reminders",
    path: routes.tasks.followUpReminders
  }
];

export const ToDoNavButton = () => {
  return (
    <Nav
      renderAsButton
      buttonProps={{ text: "To do" }}
      links={getToDoNavLinks()}
    />
  );
};
